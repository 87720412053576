@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  // transition: all 500ms ease-in-out;
}

:root {
  --body-bg: #d3b986;
  --body-bg: #f8f8f8;
  --light-text: rgb(91, 91, 91);
  --text-accent: #ffffff;
  --intro-large: #ffffff11;
  --red-accent: #e25252;
  --menu-btn-bg: rgb(255, 255, 255);
  --text-grey: rgb(65, 65, 65);
  --menu-btn-lines: rgb(255, 255, 255);
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: var(--body-bg);
  color: rgb(34, 34, 34);
  overflow-x: hidden;
  position: relative;
}

ul {
  list-style: none;
}

li {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  font-size: 2rem;
}

img {
  display: block;
  width: 100%;
}

/* Utilies */

.tooltip {
  position: relative;

  margin: 0 3px;

  &:hover {
    .tooltip-text {
      opacity: 1;
    }
  }

  .tooltip-text {
    z-index: 999;
    position: absolute;
    top: -40px;
    left: -30%;

    color: rgb(230, 230, 230);
    min-width: 100px;
    text-align: center;
    border-radius: 40px;
    opacity: 0;
    background: var(--red-accent);
    padding: 5px;
    font-size: 15px;
    transition: all 200ms ease-in-out;

    &::after {
      content: '';
      display: block;
      // background: inherit;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;

      border-top: 20px solid var(--red-accent);
      position: absolute;
      left: 30%;
      bottom: -17px;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

svg {
  transition: all 200ms ease-in-out;
  position: relative;

  &:hover {
    transform: scale(1.1);
  }
}
// .wider-container {
//   padding: 10px 5%;
// }

.container {
  // max-width: 1300px;
  margin: 0 auto;
  padding: 0 10px;
}

.largeText {
  font-size: 4rem;
}

.text-accent {
  color: var(--text-accent);
}

.flex {
  display: flex;
  gap: 10px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.bolden {
  font-weight: 500;

  span {
    font-size: 20px;
  }
}

.section-title {
  font-size: 3rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}

.section-title span {
  display: inline-block;
  position: relative;
}

.section-title span::after {
  position: absolute;
  display: block;
  content: '';
  left: 25%;

  width: 50%;
  height: 10px;
  background-color: var(--red-accent);
}

.light-text {
  color: var(--text-grey);
}

.bold {
  font-weight: bold;
}

/* End of utils */

.navbar-wrapper {
  justify-content: flex-end;
  display: flex;

  .menu-btn {
    position: fixed;
    display: block;
    background: var(--red-accent);
    height: 40px;
    width: 50px;
    right: 10px;
    top: 20px;
    cursor: pointer;
    z-index: 999;
    border-radius: 10px;
  }

  .menu-btn div {
    position: absolute;
    right: 10px;
    top: 19px;
    height: 2px;
    width: 30px;
    background: var(--menu-btn-lines);
  }

  .menu-btn div::before {
    content: '';
    position: absolute;
    top: 5px;
    height: inherit;
    width: inherit;
    background: inherit;
  }

  .menu-btn div::after {
    content: '';
    position: absolute;
    top: -5px;
    height: inherit;
    width: inherit;
    background: inherit;
  }

  min-height: 40px;
  // box-shadow: 1px 1px 10px  rgba(0, 0, 0, 0.347);

  span {
    font-weight: 700;
    font-size: 1.5em;
    letter-spacing: 0.03em;
    color: rgb(35, 35, 35);
  }

  nav {
    transition: all 500ms ease-in-out;
    position: fixed;
    z-index: 999;
    background: var(--red-accent);
    height: 100vh;
    width: 50%;
    right: 0;

    svg {
      cursor: pointer;
      position: absolute;
      top: 30px;
      right: 30px;
      transform: scale(2.6);
    }

    ul {
      margin-top: 100px;

      li {
        margin: 10px 0;
        font-size: 1.2em;
        display: block;

        a {
          display: block;
          text-align: center;
          padding: 5px 0;
          transition: translateX 200ms ease-in-out;
          cursor: pointer;

          &:hover {
            color: rgb(37, 37, 37);
            background: rgb(230, 230, 230);
          }
        }
      }
    }
  }
}

nav.closed {
  opacity: 0;
  transform: translateX(100%);
}

.intro {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .largeText {
    font-size: 4rem;
    text-shadow: 2px 2px 6px rgba(19, 19, 19, 0.608);
    span {
      color: var(--red-accent);
    }
  }

  .inner {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;

    justify-content: center;
    align-items: center;
  }

  .intro-br {
    display: none;
  }
}

.about {
  max-width: 800px;
  margin: 0 auto;

  .img-wrapper {
    border-radius: 50%;
    overflow: hidden;
  }

  img {
    width: 200px;
    height: 200px;
  }

  .summary {
    font-size: 1.5em;
    flex-direction: column;
    align-items: center;
    summary {
      p {
        font-size: 1.5rem;
        letter-spacing: 0.125rem;
        line-height: 1.25em;
        width: auto;
      }
    }
  }

  .skills {
    .item {
      margin: 40px 0;
    }

    .skill-icons {
      padding-left: 25px;
      flex-wrap: wrap;

      > svg {
        margin: 0 5px;
      }
    }
  }

  margin-bottom: 100px;
}

.projects {
  overflow-x: hidden;

  .section-title {
    margin-bottom: 60px;
  }

  .container {
    max-width: 500px;
  }

  .project {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    .shadow {
      box-shadow: none;
    }

    .basic-info {
      > p {
        font-weight: 500;
      }
    }

    .img-wrapper {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 1px 1px 10px 3px rgba(31, 31, 31, 0.293);
    }
    .project-summary {
      background: none;
      margin-bottom: 10px;
      p {
        font-size: 1.2em;
        color: var(--text-grey);
      }
    }

    .project-tech {
      color: var(--light-text);
      font-size: 1em;
      p {
        font-size: 0.8em;
      }
      margin-bottom: 30px;
    }

    .links {
      ul {
        padding-top: 15px;
        display: flex;
        li {
          margin: 0 10px;
        }

        li:nth-child(1) {
          margin-left: 0;
        }
      }

      .live {
        border-radius: 3px;
        margin-left: 0;
        padding: 5px 10px;
        font-weight: 500;
        border: 3px solid black;
        background: white;
        transition: all 300ms ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.contact {
  text-align: center;

  .container {
    max-width: 700px;
  }

  p {
    color: var(--text-grey);
    font-size: 20px;
    margin-bottom: 20px;
  }

  .email {
    display: inline-block;
    font-weight: 700;
    width: 200px;
    text-align: center;
    padding: 10px;
    text-transform: uppercase;
    border: 3px solid rgb(31, 31, 31);
    border-radius: 3px;
    transition: all 200ms ease-in-out;
  }

  .email:hover {
    background-color: rgb(31, 31, 31);
    color: white;
  }

  .social-links {
    display: flex;
    padding: 10px 0;

    justify-content: center;

    li {
      margin: 10px;
    }

    li:nth-child(even) {
      margin: 0 10px;
    }
  }
}

// Very small screens
@media screen and (max-width: 350px) {
  .section-title {
    font-size: 2.5em;
  }

  .intro {
    .largeText {
      font-size: 2em;
    }
  }

  .about {
    .summary {
      .skills {
        svg {
          width: 35px;
        }

        .item {
          margin: 10px 0;
        }
      }

      summary {
        p {
          font-size: 0.7em;
        }
      }
    }
  }
}

// Desktop
@media screen and (min-width: 700px) {
  .bolden {
    span {
      font-size: 22px;
    }
  }

  .projects {
    .container {
      max-width: 750px;
    }
    .project {
      .wrapper {
        flex-direction: row-reverse;
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .projects {
    .container {
      max-width: 1000px;
    }

    .project:nth-child(odd) {
      .wrapper {
        flex-direction: row-reverse;
      }

      .basic-info {
        background-color: jgjhkhhiijjhkkukljkl;
      }

      .links {
        display: flex;
        gap: 10px;

        .spacer {
          min-width: 513px;
        }

        ul {
          flex-basis: 40%;
        }
      }
    }

    .project {
      .wrapper {
        flex-direction: row;
        gap: 10px;
        display: flex;

        .basic-info {
          flex: 40%;
          display: flex;
          flex-direction: column;
        }

        .img-wrapper {
          max-width: 513px;
        }

        .project-tech {
          margin-top: auto;
        }
      }
    }
  }

  .navbar-wrapper {
    position: sticky;
    top: 0;
    z-index: 999;
    .menu-btn {
      display: none;
    }

    nav {
      opacity: 1;
      position: static;
      height: auto;
      width: 100%;
      transform: translateX(0);

      svg {
        display: none;
      }

      ul {
        display: flex;
        margin: 0;
        justify-content: center;

        li {
          margin: 0 10px;
          a {
            &:hover {
              background: none;
            }
          }
        }
      }
    }
  }
}
